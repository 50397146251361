import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Img, {GatsbyImageProps} from 'gatsby-image';
import {Button} from './Button';
import PageWidth from './PageWidth';
import Typography from '../constants/typography';
import {getImageFromData} from '../utils';

interface Product {
  title: string;
  text: string;
  url: string;
}

interface ProductListProps {
  products: Product[];
}

const ProductList = (props: ProductListProps) => (
  <PageWidth wide>
    <Wrapper>
      <Content>
        {props.products.map((product, index) => (
          <Product reverse={index % 2 !== 0} key={index}>
            <Info>
              <ProductTitle>{product.title}</ProductTitle>
              <Paragraph>{product.text}</Paragraph>
              {product.url && (
                <Actions>
                  <Link to={product.url} style={{border: 'none'}}>
                    <ActionButton tabIndex="-1" title="Learn more">
                      Learn More
                    </ActionButton>
                  </Link>
                </Actions>
              )}
            </Info>
            <ImageWrapper>
              <Image fluid={getImageFromData(product, 'image')} />
            </ImageWrapper>
          </Product>
        ))}
      </Content>
    </Wrapper>
  </PageWidth>
);

const Wrapper = styled.section`
  padding-bottom: 4em;
  @media (max-width: 1100px) {
    grid-template-columns: 100%;
    width: 90%;
    margin: auto;
    margin-top: 2rem;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 60%;
  line-height: 1.5;
  height: 400px;

  @media (max-width: 1100px) {
    margin-left: 0px;
  }

  @media (max-width: 800px) {
    flex-basis: 100%;
  }
`;

const Product = styled.div<{reverse: boolean}>`
  display: flex;
  margin-top: 4rem;
  margin-bottom: 2rem;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};

  ${Info} {
    margin-left: ${(props) => (props.reverse ? '3em' : 0)};
    margin-right: ${(props) => (props.reverse ? 0 : '3em')};
  }

  @media (max-width: 1100px) {
    border: none;
    padding-top: 20px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 0;
    ${Info} {
      margin: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-content: center;
  flex-basis: 65%;
`;

const Image = styled(Img)<GatsbyImageProps>`
  width: 100%;
  padding: 5px;

  @media (max-width: 800px) {
    flex-basis: 100%;
    margin-top: 4em;
  }
`;

const ProductTitle = styled.h3`
  font-size: ${Typography.title.desktop.fontSize};
  line-height: ${Typography.title.desktop.lineHeight};
  margin-bottom: 0.2em;

  @media (max-width: 800px) {
    font-size: ${Typography.title.mobile.fontSize};
  }
`;

const Paragraph = styled.p`
  font-size: ${Typography.body.desktop.fontSize};
  line-height: ${Typography.body.desktop.lineHeight};
  color: ${Typography.body.color};

  @media (max-width: 800px) {
    font-size: ${Typography.body.mobile.fontSize};
    line-height: ${Typography.body.mobile.lineHeight};
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 2rem;
`;

const ActionButton = styled(Button)<{tabIndex: string}>`
  margin-right: 1rem;
  font-size: ${Typography.button.fontSize};
  padding: 8px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div``;

export default ProductList;
