import React from 'react';
import {graphql, PageProps} from 'gatsby';
import SEO from '../components/SEO';
import {Layout} from '../components/Layout';
import HeaderImage from '../components/HeaderImage';
import ProductList from '../components/ProductList';
import PageWidth from '../components/PageWidth';
import {getImageFromData} from '../utils';
import {ProductsQuery} from './__generated__/ProductsQuery';

const Products = (props: PageProps<ProductsQuery>) => {
  const data = props.data.en.edges[0].node;
  const headerImage = getImageFromData(data, 'headerImage');

  return (
    <Layout>
      <SEO
        title={data.pageTitle}
        keywords={data.metaKeywords.split(',')}
        description={data.metaDescription}
      />
      <HeaderImage src={headerImage}>
        <h1>{data.pageTitle}</h1>
      </HeaderImage>
      <PageWidth wide>
        <ProductList products={data.products} />
      </PageWidth>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ProductsQuery {
    en: allContentfulAmpifyWebProducts(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          metaKeywords
          metaDescription
          pageTitle
          headerImage {
            ...AmpifyHeaderImage
          }
          products {
            ... on ContentfulAmpifyWebBlocswave {
              url: relativeUrl
              text: introText
              title: introSubtitle
              image: heroVideoFrame {
                ...AmpifyBodyImage
              }
            }
            ... on ContentfulAmpifyWebGroovebox {
              url: relativeUrl
              text: introText
              title: introSubtitle
              image: heroVideoFrame {
                ...AmpifyBodyImage
              }
            }
            ... on ContentfulAmpifyWebLaunchpad {
              url: relativeUrl
              text: introText
              title: introSubtitle
              image: heroVideoFrame {
                ...AmpifyBodyImage
              }
            }
            ... on ContentfulAmpifyWebStudioCustom {
              url: relativeUrl
              text: heroParagraph
              title: pageTitle
              image: yellowSectionImage {
                ...AmpifyBodyImage
              }
            }
          }
        }
      }
    }
  }
`;

export default Products;
